import './main.css'
import * as THREE from 'three'
import {BoxBufferGeometry, MeshBasicMaterial, Raycaster, Sphere, SphereBufferGeometry} from 'three'
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls.js'
import {GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js'
import {
    ClickableMesh,
    StateMaterialSet,
    StateMaterial,
    MouseEventManager,
    ThreeMouseEvent,
    ThreeMouseEventType,
} from "@masatomakino/threejs-interactive-object"

/**
 * Loaders
 * */
let sceneReady = false

window.setTimeout(()=>{
    sceneReady = true
}, 1000)

/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')
canvas.style.cursor = "move"
/**
 * Models
 */

const gltfLoader = new GLTFLoader()


gltfLoader.load(
    '/models/metalbot/robotmetal.gltf',
    (gltf) =>
    {
        const model = gltf.scene.children[0]
        const animations = gltf.animations

        scene.add(model)
        let mixer = new THREE.AnimationMixer(model)
        let action = mixer.clipAction(animations[0])

        model.scale.set(0.02,0.02,0.02)
        model.position.set(0,-2,0)
        model.rotation.z = 5

        action.play()
        let delta = clock.getDelta() // clock is an instance of THREE.Clock
        if ( mixer ) mixer.update( delta )
    }
)



/**
 * Points of interest
 * */

// Scene
const scene = new THREE.Scene()


/**
 * Lights
 */

const pointLight = new THREE.PointLight(0xffffff, 4)
pointLight.position.x = 0
pointLight.position.z = 4
pointLight.position.y = 4
scene.add(pointLight)

const pointLight2 = new THREE.PointLight(0xffffff, 1)
pointLight2.position.x = 0
pointLight2.position.z = -4
scene.add(pointLight2)


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: 600
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = 600

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.001, 15000)
camera.position.x = 3
camera.position.y = 0
camera.position.z = 1
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.enableZoom = false
controls.minPolarAngle = Math.PI/2 // radians
controls.maxPolarAngle = Math.PI/2
controls.enablePan = false
/**
 * Renderer
 */

const renderer = new THREE.WebGLRenderer({
    canvas: canvas
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFSoftShadowMap
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
/**
 * Animate
 */
const clock = new THREE.Clock()

const createPathStrings = (filename) => {
    const basePath = "/textures/skybox/"
    const baseFilename = basePath + filename
    const fileType = ".png"
    const sides = ["ft", "bk", "up", "dn", "rt", "lf"]
    return sides.map(side => {
        return baseFilename + "_" + side + fileType
    })
}

let skyboxImage = "space"
const createMaterialArray = (filename) => {
    const skyboxImagepaths = createPathStrings(filename)
    return skyboxImagepaths.map(image => {
        let texture = new THREE.TextureLoader().load(image)
        let imageName = image.toString().replace("/textures/skybox/","")
        if (imageName === "space_up.png" || imageName === "space_dn.png"){
            texture.rotation = Math.PI
            texture.center.x = 0.5
            texture.center.y = 0.5

        }
        return new THREE.MeshBasicMaterial({map: texture, side: THREE.BackSide}) // <---
    })
}

/**
 * Skybox
 * */
const materialArray = createMaterialArray(skyboxImage)
const skyboxGeo = new THREE.BoxGeometry(10000, 10000, 10000)
const skybox = new THREE.Mesh(skyboxGeo, materialArray)
scene.add(skybox)

/**
 * Clickable buttons
 * */
const manager = new MouseEventManager(scene, camera, canvas)
const clickable = new ClickableMesh({
    geo: new SphereBufferGeometry(0.1, 25),
    material: new StateMaterialSet({
        normal: new MeshBasicMaterial({
            color: '#0D21A1',
        }),
    }),
})
clickable.position.set(0.26,0.65,0)
scene.add(clickable)

// Event listener

let toggleText1 = true
clickable.addEventListener(ThreeMouseEventType.CLICK, (e) => {
    let textElement =  document.getElementById("text2")
    if(toggleText1){
        textElement.classList.add('block')
        textElement.classList.remove('hidden')
    }
    else{
        textElement.classList.add('hidden')
        textElement.classList.remove('block')
    }


    toggleText1 =! toggleText1
    console.log(toggleText1)
})
clickable.addEventListener(ThreeMouseEventType.OVER, (e) => {
    clickable.material.color = new THREE.Color( '#081256' );
    canvas.style.cursor = "pointer"

})
clickable.addEventListener(ThreeMouseEventType.OUT, (e) => {
    clickable.material.color = new THREE.Color( '#0D21A1' );
    canvas.style.cursor = "move"
})

// Second clickable
const clickable2 = new ClickableMesh({
    geo: new SphereBufferGeometry(0.1, 25),
    material: new StateMaterialSet({
        normal: new MeshBasicMaterial({
            color: '#0D21A1',
        }),
    }),
})
clickable2.position.set(0.26,-0.8,0.5)
scene.add(clickable2)

let toggleText2 = true
// Event listener
clickable2.addEventListener(ThreeMouseEventType.CLICK, (e) => {
    let textElement =  document.getElementById("text3")
    if(toggleText2){
        textElement.classList.add('block')
        textElement.classList.remove('hidden')
    }
    else{
        textElement.classList.add('hidden')
        textElement.classList.remove('block')
    }


    toggleText2 =! toggleText2
    console.log(toggleText2)
})
clickable2.addEventListener(ThreeMouseEventType.OVER, (e) => {
    clickable2.material.color = new THREE.Color( '#081256' );
    canvas.style.cursor = "pointer"

})
clickable2.addEventListener(ThreeMouseEventType.OUT, (e) => {
    clickable2.material.color = new THREE.Color( '#0D21A1' );
    canvas.style.cursor = "move"
})

// Third clickable
const clickable3 = new ClickableMesh({
    geo: new SphereBufferGeometry(0.1, 25),
    material: new StateMaterialSet({
        normal: new MeshBasicMaterial({
            color: '#0D21A1',
        }),
    }),
})
clickable3.position.set(-0.1,1.35,0.2)
scene.add(clickable3)

// Event listener
let toggleText3 = true
clickable3.addEventListener(ThreeMouseEventType.CLICK, (e) => {
    let textElement =  document.getElementById("text1")
    if(toggleText3){
        textElement.classList.add('block')
        textElement.classList.remove('hidden')
    }
    else{
        textElement.classList.add('hidden')
        textElement.classList.remove('block')
    }


    toggleText3 =! toggleText3
    console.log(toggleText3)
})
clickable3.addEventListener(ThreeMouseEventType.OVER, (e) => {
    clickable3.material.color = new THREE.Color( '#081256' );
    canvas.style.cursor = "pointer"

})
clickable3.addEventListener(ThreeMouseEventType.OUT, (e) => {
    clickable3.material.color = new THREE.Color( '#0D21A1' );
    canvas.style.cursor = "move"
})

// Executes each frame
const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()



